/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserPdf } from '../models/UserPdf';
import type { UserPdfInput } from '../models/UserPdfInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UserPdfsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create UserPdf
     * @returns UserPdf successful
     * @throws ApiError
     */
    public createUserPdf({
        requestBody,
    }: {
        requestBody: {
            audioRecorderFile?: UserPdfInput;
        },
    }): CancelablePromise<UserPdf> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/user_pdfs',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
            },
        });
    }
}
